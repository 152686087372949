import { GetStaticProps } from 'next'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useState } from 'react'

import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Img from '@/components/common/Img'
import Layout from '@/components/Layout'
import { EN_LOGO_IMAGE, KO_LOGO_IMAGE } from '@/constants/image'

function Home() {
  const { t } = useTranslation('common')
  const { locale } = useRouter()
  const [hoveredSection, setHoveredSection] = useState<number | null>(null)

  const sections = [
    {
      title: t('Home.programInfo'),
      desc: t('Home.programInfoDesc'),
      link: '/programs',
    },
    { title: t('Home.chefList'), desc: t('Home.chefListDesc'), link: '/chefs' },
    // {
    //   title: t('Home.restaurantSearch'),
    //   desc: t('Home.restaurantSearchDesc'),
    //   link: '/restaurants',
    // },
  ]

  return (
    <Layout>
      <div className="bg-background text-white font-sans overflow-hidden">
        <header className="p-8 z-10 flex items-center justify-center">
          {locale === 'ko' ? (
            <Img alt="한국 로고" height={100} src={KO_LOGO_IMAGE} width={200} />
          ) : (
            <Img alt="영어 로고" height={100} src={EN_LOGO_IMAGE} width={200} />
          )}
        </header>
        <main className="flex justify-center items-center h-[calc(100vh-300px)] relative">
          {sections.map((section, index) => (
            <Link
              key={section.title}
              className={`h-4/5 ${
                hoveredSection === index ? 'w-1/2 md:w-1/3' : 'w-1/3 md:w-1/5'
              } ${
                index % 2 === 0 ? 'bg-white text-black' : 'bg-black text-white'
              } transition-all duration-500 flex flex-col justify-end p-4 md:p-8 relative overflow-hidden cursor-pointer`}
              href={section.link}
              onMouseEnter={() => setHoveredSection(index)}
              onMouseLeave={() => setHoveredSection(null)}>
              <div
                className={`absolute inset-0 bg-cover bg-center transition-opacity duration-500 ${
                  hoveredSection === index ? 'opacity-30' : 'opacity-0'
                }`}
                style={{
                  backgroundImage: `url(/image${index + 1}.jpg)`,
                }}></div>
              <h2 className="text-2xl font-bold mb-4 relative z-10 break-keep">
                {section.title}
              </h2>
              <p
                className={`text-base relative z-10 transition-opacity duration-500 min-h-[120px] ${
                  hoveredSection === index ? 'opacity-100' : 'opacity-0'
                }`}>
                {section.desc}
              </p>
            </Link>
          ))}
        </main>
      </div>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  if (!locale) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default Home
